<template>
  <a-spin :spinning="spinning">
    <div :id="chartId" :style="{ height: `${height}px` }"></div>
  </a-spin>
</template>

<script>
import { Chart } from '@antv/g2'
import { humanizeValue } from '@/utils'
import { chartColors } from '@/utils/const'

const chart = {}

export default {
  name: 'LineChart',
  props: {
    datetimeRange: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      default: 240
    },
    historyFunc: {
      type: Function,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    inKey: {
      type: String,
      required: true
    },
    outKey: {
      type: String,
      required: true
    },
    networkId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      spinning: false
    }
  },
  computed: {
    chartId () {
      return `${this.name}.chart`
    }
  },
  mounted () {
    this.render()
  },
  methods: {
    async render () {
      if (chart[this.chartId]) chart[this.chartId].destroy()
      chart[this.chartId] = new Chart({
        container: this.chartId,
        autoFit: true,
        appendPadding: [8, 16, 8, 64]
      })
      chart[this.chartId].scale({
        timestamp: {
          type: 'time',
          mask: 'MM-DD HH:mm'
        },
        value: {
          min: 0,
          nice: true,
          formatter: value => humanizeValue(value)
        }
      })
      chart[this.chartId].legend(false)
      chart[this.chartId].tooltip({
        shared: true,
        showCrosshairs: true
      })
      chart[this.chartId].facet('mirror', {
        fields: ['name'],
        showTitle: false,
        padding: [0],
        eachView (view, facet) {
          const rowIndex = facet.rowIndex
          view
            .line()
            .position('timestamp*value')
            .color('name', chartColors[rowIndex])
          view
            .area()
            .position('timestamp*value')
            .color('name', chartColors[rowIndex])
          const data = facet.data
          if (data.length !== 0) {
            let max = data[0]
            let min = data[0]
            for (const datum of data) {
              if (datum.value > max.value) max = datum
              if (datum.value < min.value) min = datum
            }
            view.annotation().dataMarker({
              point: {
                style: {
                  stroke: '#ff9671'
                }
              },
              direction: 'downward',
              top: true,
              position: [max.timestamp, max.value],
              text: {
                content: `峰值: ${humanizeValue(max.value)}`
              }
            })
            if (max.timestamp !== min.timestamp) {
              view.annotation().dataMarker({
                point: {
                  style: {
                    stroke: '#ff9671'
                  }
                },
                top: true,
                position: [min.timestamp, min.value],
                text: {
                  content: `谷值: ${humanizeValue(min.value)}`
                }
              })
            }
          }
        }
      })
      this.spinning = true
      const chartData = []
      await this.historyFunc(this.networkId, {
        key: `net.if.in[${this.inKey}]`,
        datetime_from: this.datetimeRange[0].format('YYYY-MM-DD HH:mm'),
        datetime_to: this.datetimeRange[1].format('YYYY-MM-DD HH:mm')
      }).then(res => {
        res.data.data.forEach(item => {
          chartData.push({
            timestamp: item.timestamp,
            name: '入流量',
            value: item.value
          })
        })
      })
      await this.historyFunc(this.networkId, {
        key: `net.if.out[${this.outKey}]`,
        datetime_from: this.datetimeRange[0].format('YYYY-MM-DD HH:mm'),
        datetime_to: this.datetimeRange[1].format('YYYY-MM-DD HH:mm')
      }).then(res => {
        res.data.data.forEach(item => {
          chartData.push({
            timestamp: item.timestamp,
            name: '出流量',
            value: item.value
          })
        })
      })
      chart[this.chartId].data(chartData)
      chart[this.chartId].render()
      this.spinning = false
    }
  },
  watch: {
    datetimeRange () {
      this.render()
    }
  }
}
</script>
