<template>
  <a-modal
    v-model="visible"
    :after-close="afterClose"
    :destroy-on-close="true"
    :footer="null"
    :title="name"
    :width="1080"
  >
    <a-space size="large" style="margin-bottom: 24px">
      <datetime-range
        :allow-clear="false"
        :default-value="defaultValue"
        @ok="
          v => {
            datetimeRange = v
          }
        "
      ></datetime-range>
      <a-checkbox v-model="compareYesterday">
        <question-icon title="环比" description="昨天同时段"></question-icon>
      </a-checkbox>
      <a-checkbox v-model="compareLastWeek">
        <question-icon title="同比" description="上周同时段"></question-icon>
      </a-checkbox>
    </a-space>

    <multi-line-chart
      :compare-last-week="compareLastWeek"
      :compare-yesterday="compareYesterday"
      :datetime-range="datetimeRange"
      :history-func="historyFunc"
      :name="name"
      :in-key="inKey"
      :out-key="outKey"
      :networkId="networkId"
    ></multi-line-chart>
  </a-modal>
</template>

<script>
import DatetimeRange from '@/components/DatetimeRange'
import QuestionIcon from '@/components/icon/QuestionIcon'
import MultiLineChart from './modules/MultiLineChart.vue'

export default {
  name: 'ModalChart',
  components: {
    DatetimeRange,
    MultiLineChart,
    QuestionIcon
  },
  props: {
    defaultValue: {
      type: Array,
      required: true
    },
    historyFunc: {
      type: Function,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    inKey: {
      type: String,
      required: true
    },
    outKey: {
      type: String,
      required: true
    },
    networkId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      visible: false,
      datetimeRange: this.defaultValue || [],
      compareYesterday: false,
      compareLastWeek: false
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    afterClose () {
      this.compareYesterday = false
      this.compareLastWeek = false
    }
  }
}
</script>
