<template>
  <a-card size="small" :title="`${name} (bps)`">
    <a-space slot="extra">
      <a-icon type="fullscreen" @click="$refs.modalChart.show()"></a-icon>
    </a-space>
    <line-chart
      :datetime-range="datetimeRange"
      :history-func="historyFunc"
      :name="name"
      :in-key="inKey"
      :out-key="outKey"
      :networkId="networkId"
    ></line-chart>

    <modal-chart
      ref="modalChart"
      :default-value="datetimeRange"
      :history-func="historyFunc"
      :name="name"
      :in-key="inKey"
      :out-key="outKey"
      :networkId="networkId"
    ></modal-chart>
  </a-card>
</template>

<script>
import LineChart from './modules/LineChart.vue'
import ModalChart from './modules/ModalChart/index.vue'

export default {
  name: 'NetworkTrafficChartCard',
  components: {
    LineChart,
    ModalChart
  },
  props: {
    datetimeRange: {
      type: Array,
      required: true
    },
    historyFunc: {
      type: Function,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    inKey: {
      type: String,
      required: true
    },
    networkId: {
      type: String,
      required: true
    },
    outKey: {
      type: String,
      required: true
    }
  }
}
</script>
