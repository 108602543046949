<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="appstore"></a-icon>
          <span>专线</span>
        </a-space>
      </template>
      <span>专线包含名称、带宽等。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :getTableDataFunc="getTableDataFunc"
        :exportFunc="exportFunc"
        :columnDefaultSelected="columnDefaultSelected"
        :columnsAll="columnsAll"
        :toolsList="['setting', 'reload', 'search', 'leftTool', 'export']"
        :refresh="refresh"
        :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
      >
        <!-- 工具栏 -->
        <template #leftTool>
          <create-button
            v-permission="{action: 'base.dedicated_line.create', effect: 'disabled'}"
            @click="$refs.inputDrawer.show()"
          >
          </create-button>
        </template>
        <!-- 表格自定义列 -->
        <template #name="{ slotProps }">
          <a-tooltip placement="topLeft" :title="slotProps.text">
            <a @click="$refs.detailDrawer.show(slotProps.record.id)">
              {{ slotProps.text }}
            </a>
          </a-tooltip>
        </template>
        <template #bandwidth="{ slotProps }">
          {{ `${slotProps.text} M` }}
        </template>
        <template #networkDevice="{ slotProps }">
          <a-tooltip v-if="slotProps.text" placement="topLeft" :title="slotProps.text.name">
            <a @click="showNetworkDrawer(slotProps.record)">
              {{ slotProps.text.name }}
            </a>
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template #networkInterface="{ slotProps }">
          <a-tooltip v-if="slotProps.text" placement="topLeft" :title="slotProps.text.name">
            {{ slotProps.text.name }}
          </a-tooltip>
          <span v-else>-</span>
        </template>
        <template #statusTitle>
        <status-question-icon></status-question-icon>
      </template>
      <template #status="{ slotProps }">
        <source-status-tag v-if="slotProps.text" :status="slotProps.text"></source-status-tag>
        <span v-else>-</span>
      </template>
        <template #operation="{ slotProps }">
          <edit-button
          v-permission="{action: 'base.dedicated_line.update', effect: 'disabled'}"
            @click="$refs.inputDrawer.show(slotProps.record.id)"
          ></edit-button>
          <delete-button
          v-permission="{action: 'base.dedicated_line.delete', effect: 'disabled'}"
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>
    </a-card>

    <input-drawer ref="inputDrawer" @ok="confirm"></input-drawer>
    <dedicated-line-drawer ref="detailDrawer"></dedicated-line-drawer>
    <switch-drawer ref="switchDrawer"></switch-drawer>
    <security-drawer ref="securityDrawer"></security-drawer>
  </div>
</template>

<script>
import { securityType, sourceStatuses } from '@/utils/const'
import {
  deleteDedicatedLine,
  exportDedicatedLine,
  getDedicatedLineList
} from '@/api/dedicated-line'
import { getNetworkDevice } from '@/api/network-device'
import ListLayout from '@/components/ListLayout'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'
import InputDrawer from './modules/InputDrawer'
import DedicatedLineDrawer from '@/components/drawer/DedicatedLineDrawer.vue'
import SourceStatusTag from '@/components/tag/SourceStatusTag'
import StatusQuestionIcon from '@/components/icon/StatusQuestionIcon'
import SwitchDrawer from '@/components/drawer/SwitchDrawer'
import SecurityDrawer from '@/components/drawer/SecurityDrawer'

export default {
  name: 'DedicatedLine',
  components: {
    DeleteButton,
    EditButton,
    InputDrawer,
    ListLayout,
    DedicatedLineDrawer,
    SourceStatusTag,
    StatusQuestionIcon,
    SwitchDrawer,
    SecurityDrawer,
    CreateButton
  },
  data () {
    return {
      exportFunc: exportDedicatedLine,
      getTableDataFunc: getDedicatedLineList,
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          fixed: 'left',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'description',
          title: '描述',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'description'
          }
        },
        {
          dataIndex: 'bandwidth',
          title: '带宽',
          width: 100,
          scopedSlots: {
            customRender: 'bandwidth'
          }
        },
        {
          dataIndex: 'status',
          width: 100,
          slots: {
            title: 'statusTitle',
            name: '状态'
          },
          scopedSlots: {
            customRender: 'status'
          },
          filteredValue: [],
          filters: sourceStatuses.map(value => {
            return {
              text: this.$t(`source_status.${value}`),
              value
            }
          }),
          filterMultiple: false
        },
        {
          dataIndex: 'network_device',
          title: '网络设备',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'networkDevice'
          }
        },
        {
          dataIndex: 'network_interface',
          title: '网络端口',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'networkInterface'
          }
        },
        {
          dataIndex: 'created_at',
          title: '创建时间',
          width: 200
        },
        {
          dataIndex: 'updated_at',
          title: '更新时间',
          width: 200
        }
      ],
      columnDefaultSelected: [
        'name',
        'description',
        'bandwidth',
        'status',
        'network_device',
        'network_interface'
      ],
      refresh: false,
      tableSelectData: []
    }
  },
  methods: {
    confirm (id = '') {
      if (id) {
        deleteDedicatedLine(id).then(res => {
          this.$message.success(res.message)
          this.refresh = !this.refresh
        })
        this.tableSelectData.splice(this.tableSelectData.findIndex(item => item.key === id), 1)
      } else {
        this.refresh = !this.refresh
      }
    },
    showNetworkDrawer (item) {
      const id = item.network_device.id
      getNetworkDevice(id).then(res => {
        if (res.data) {
          const type = res.data.device_type
          if (type === 'switch') this.$refs.switchDrawer.show(id)
          else if (securityType.findIndex(v => v === type) !== -1) this.$refs.securityDrawer.show(id)
          else if (type === 'access_controller') this.$router.push({ name: 'ACAPDetail', params: { id } })
        }
      })
    }
  }
}
</script>
