<template>
  <a-drawer
    :destroy-on-close="true"
    :title="isUpdate ? '专线编辑' : '专线创建'"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item label="名称" prop="name">
        <a-input
          v-model="form.name"
          :disabled="isUpdate"
          :max-length="128"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="描述" prop="description">
        <a-input v-model="form.description" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="带宽" prop="bandwidth">
        <a-input
          v-model="form.bandwidth"
          :disabled="isUpdate"
          :min="1"
          suffix="M"
          type="number"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="网络设备" prop="networkDevice">
        <source-select
          v-model="form.networkDevice"
          :disabled="isUpdate"
          source-type="network_device"
        ></source-select>
      </a-form-model-item>
      <a-form-model-item
        v-if="form.networkDevice"
        label="网口"
        prop="networkInterface"
      >
        <network-interface-select
          v-model="form.networkInterface"
          :disabled="isUpdate"
          :dataSource="options"
          :form="params"
          :total="total"
          @popupScroll="popupScroll"
        ></network-interface-select>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {
  createDedicatedLine,
  getDedicatedLine,
  updateDedicatedLine
} from '@/api/dedicated-line'
import { getNetworkDeviceNetworkInterfaceList } from '@/api/network-device'
import SourceSelect from '@/components/select/SourceSelect'
import NetworkInterfaceSelect from '@/components/select/NetworkInterfaceSelect'

export default {
  name: 'InputDrawer',
  components: {
    SourceSelect,
    NetworkInterfaceSelect
  },
  data () {
    return {
      visible: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        description: '',
        bandwidth: '',
        networkDevice: undefined,
        networkInterface: undefined
      },
      rules: {
        name: [
          {
            message: '请输入名称',
            required: true,
            trigger: 'blur'
          }
        ],
        bandwidth: [
          {
            message: '请输入专线带宽',
            required: true,
            trigger: 'blur'
          }
        ],
        networkDevice: [
          {
            message: '请选择网络设备',
            required: true,
            trigger: 'change'
          }
        ],
        networkInterface: [
          {
            message: '请选择网口',
            required: true,
            trigger: 'change'
          }
        ]
      },
      options: [],
      loading: false,
      params: {
        page: 1,
        page_size: 10
      },
      total: 0,
      id: ''
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        getDedicatedLine(id)
          .then(res => {
            const data = res.data
            this.form = {
              id: data.id,
              name: data.name,
              description: data.description,
              bandwidth: data.bandwidth,
              networkDevice: data.network_device,
              networkInterface: {
                label: data.network_interface.name,
                key: data.network_interface.id
              }
            }
          })
          .finally(() => {
            this.visible = true
          })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    closeDrawer () {
      this.form = {
        id: '',
        name: '',
        description: '',
        bandwidth: '',
        networkDevice: undefined,
        networkInterface: undefined
      }
      this.params = {
        page: 1,
        page_size: 10
      }
      this.total = 0
      this.options = []
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const params = {
            description: this.form.description
          }
          this.loading = true
          if (this.isUpdate) {
            updateDedicatedLine(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            params.name = this.form.name
            params.bandwidth = this.form.bandwidth
            params.network_device_id = this.form.networkDevice.id
            // 未封装，使用 value
            params.network_interface_id = this.form.networkInterface.value
            createDedicatedLine(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
    popupScroll () {
      this.params.page++
      getNetworkDeviceNetworkInterfaceList(this.id, this.params).then(res => {
        this.total = res.data.total
        const arr = []
        res.data.data.forEach(item => {
          if (this.options.findIndex(option => option.id === item.id) === -1) arr.push(item)
        })
        this.options.push(...arr)
      })
    }
  },
  watch: {
    'form.networkDevice': {
      handler (v) {
        if (!this.isUpdate && v) {
          this.id = v.id
          this.form.networkInterface = undefined
          this.options = []
          this.params = {
            page: 1,
            page_size: 10
          }

          getNetworkDeviceNetworkInterfaceList(this.id, this.params).then(res => {
            this.total = res.data.total
            this.options.push(...res.data.data)
          })
        }
      },
      deep: true
    }
  }
}
</script>
