<template>
  <a-drawer
    class="detail-drawer"
    :body-style="{ padding: '8px 16px', overflow: 'hidden' }"
    :destroy-on-close="true"
    :visible="visible"
    :width="1200"
    @close="visible = false"
  >
    <a-tabs type="card">
      <a-tab-pane key="overview" tab="总览">
        <a-row
          ref="sliderRow"
          :gutter="24"
          style="flex-flow: nowrap; height: calc(100vh - 80px); overflow: auto"
        >
          <a-col :sm="0" :lg="4">
            <a-anchor
              :wrapperStyle="{ background: 'transparent' }"
              :offsetTop="16"
              :getContainer="() => $refs.sliderRow.$el"
            >
              <a-anchor-link href="#detail" title="基本信息" />
              <a-anchor-link href="#traffic" title="流量" />
            </a-anchor>
          </a-col>
          <a-col :sm="24" :lg="20">
            <div id="detail">
              <div class="small-module-title">
                <a-icon type="profile"></a-icon>
                <span> 基本信息</span>
              </div>
              <div style="padding: 0 16px">
                <a-descriptions :column="3">
                  <a-descriptions-item label="名称">
                    {{ detail.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="描述">
                    {{ detail.description }}
                  </a-descriptions-item>
                  <a-descriptions-item label="状态">
                    <source-status-tag
                      v-if="detail.status"
                      :status="detail.status"
                    ></source-status-tag>
                    <span v-else>-</span>
                  </a-descriptions-item>
                  <a-descriptions-item label="带宽">
                    {{ detail.bandwidth }} M
                  </a-descriptions-item>
                  <a-descriptions-item
                    label="网络设备"
                    v-if="detail.network_device"
                  >
                    {{ detail.network_device.name }}
                  </a-descriptions-item>
                  <a-descriptions-item
                    label="网口"
                    v-if="detail.network_interface"
                  >
                    {{ detail.network_interface.name }}
                  </a-descriptions-item>
                  <a-descriptions-item label="创建时间">
                    {{ detail.created_at }}
                  </a-descriptions-item>
                  <a-descriptions-item label="更新时间">
                    {{ detail.updated_at }}
                  </a-descriptions-item>
                </a-descriptions>
              </div>
            </div>
            <div id="traffic" style="margin-top: 8px;">
              <div class="small-module-title">
                <a-icon type="swap"></a-icon>
                <span> 流量</span>
              </div>
              <div style="padding: 0 16px">
                <div style="margin-bottom: 16px">
                  <datetime-range
                    :allow-clear="false"
                    :default-value="datetimeRange"
                    @ok="v => { datetimeRange = v }"
                  ></datetime-range>
                </div>
                <network-traffic-chart-card
                  v-if="detail.network_device"
                  :inKey="inKey"
                  :outKey="outKey"
                  name="流量"
                  :history-func="historyFunc"
                  :datetimeRange="datetimeRange"
                  :networkId="detail.network_device.id"
                ></network-traffic-chart-card>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </a-drawer>
</template>

<script>
import moment from 'moment'
import { getDedicatedLine } from '@/api/dedicated-line'
import { getNetworkDeviceMonitorHistory } from '@/api/network-device'
import DatetimeRange from '@/components/DatetimeRange'
import NetworkTrafficChartCard from '@/components/card/NetworkTrafficChartCard/index.vue'
import SourceStatusTag from '@/components/tag/SourceStatusTag'

export default {
  name: 'DedicatedLineDrawer',
  components: {
    DatetimeRange,
    NetworkTrafficChartCard,
    SourceStatusTag
  },
  data () {
    return {
      visible: false,
      detail: {
        id: '',
        name: '',
        description: '',
        bandwidth: '',
        network_device: undefined,
        network_interface: undefined
      },
      datetimeRange: [moment().subtract(1, 'hour'), moment()],
      historyFunc: getNetworkDeviceMonitorHistory
    }
  },
  computed: {
    inKey () {
      if (this.detail.network_interface) {
        return `ifHCInOctets.${this.detail.network_interface.index}`
      }
      return undefined
    },
    outKey () {
      if (this.detail.network_interface) {
        return `ifHCOutOctets.${this.detail.network_interface.index}`
      }
      return undefined
    }
  },
  methods: {
    show (id) {
      getDedicatedLine(id)
        .then(res => {
          this.detail = res.data
        })
        .finally(() => {
          this.visible = true
        })
    }
  }
}
</script>

<style lang="less">
.detail-drawer {
  .small-module-title{
    height: 35px;
    background: #E4F1FF;
    border-radius: 8px;
    color: #096DD9;
    padding: 0 16px;
    margin-bottom: 16px;

    i {
      font-size: 16px;
      height: 20px;
      vertical-align: sub;
    }
    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      line-height: 37px;
      vertical-align: middle;
    }
  }

  .button-container {
    text-align: right;
    padding: 0 0 8px 0;

    a {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .active {
      color: #1890ff;
    }
  }

  .unit-card {
    .ant-descriptions-row > th,
    .ant-descriptions-row > td {
      padding-bottom: 0;
    }
  }
}
</style>
